// Entry point for the build script in your package.json
import {Turbo} from "@hotwired/turbo-rails"
import {get} from "@rails/request.js"
import "./controllers"
import * as bootstrap from "bootstrap"

import "trix"
import "@rails/actiontext"
import ahoy from "ahoy.js";

Trix.config.textAttributes.code = {
  tagName: 'code',
  parse: true,
  nestable: false,
  exclusive: true,
};

/* Disable files */
document.addEventListener("trix-file-accept", function (event) {
  console.warn("File attachment not allowed");
  event.preventDefault()
});

/**
 * Visit a URL from the stream action
 */
Turbo.StreamActions.visit = function () {
  const url = this.getAttribute('url') || '/'
  const format = this.getAttribute('format') || '/'
  const delay = this.getAttribute('delay') || 0;
  // Turbo.visit(url, { frame: '_top', action: 'advance' })

  if (format === "turbo_stream") {
    setTimeout(() => {
      console.log("Making turbo stream visit");
      get(url, {responseKind: "turbo-stream"});
    }, delay);
    return;
  }

  setTimeout(() => {
    Turbo.visit(url);
  }, delay);
}

/**
 * Submit a form with a delay
 */
Turbo.StreamActions.submit_form = function () {
  const form_id = this.getAttribute('form_id')
  const delay = this.getAttribute('delay') || 0;

  if (form_id == null) {
    return;
  }

  setTimeout(() => {
    document.getElementById(form_id).requestSubmit();
  }, delay);
}

/**
 * Reload the page duh
 */
Turbo.StreamActions.reload = function () {
  const delay = this.getAttribute('delay') || 0;

  setTimeout(() => {
    window.location.reload();
  }, delay);
}



// TEST AREA
document.addEventListener('turbo:load', () => {
  // document.getElementById('job-hired-button').click();
});