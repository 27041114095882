import {Controller} from "@hotwired/stimulus"
import {Toast} from "bootstrap";

export default class extends Controller {
  static values = {
    variant: {type: String, default: "info"},
    message: String
  }

  initialize() {
    this.toastId = `toast-${Date.now().valueOf()}`;
    console.debug("Toast: initialized", this.toastId);

    setTimeout(() => {
      this.createToast();
    });
  }

  connect() {
    console.debug("Toast: connected", this.toastId);
  }

  createToast() {
    const toastDocumentFragment = this.element.content.cloneNode(true);

    // Add fragment to the DOM
    const toastDocumentFragmentDiv = toastDocumentFragment.querySelector('.toast')
    toastDocumentFragmentDiv.id = this.toastId;
    this.toastContainer.append(toastDocumentFragmentDiv);

    // Get the DOM Node for Bootstrap to be accepted
    const toastNode = this.toastContainer.querySelector('#' + this.toastId);
    // TODO show animation progress when the toast will be hidden
    const toast = new Toast(toastNode, {
      animation: true,
      autohide: true,
      delay: 8000
    });

    toastNode.addEventListener("hidden.bs.toast", () => {
      console.debug("Toast: Request to hide", this.toastId,
        document.querySelector("#toasts"));
      toast.dispose();

      const toastNode = this.toastContainer.querySelector('#' + this.toastId);
      if (toastNode) {
        toastNode.remove();
      }

    }, {once: true});

    toast.show();

    // Remove the template and only left the Toast in the DOM
    this.element.remove();
  }


  get toastContainer() {
    return document.getElementById('toasts');
  }

  disconnect() {
    console.debug("/ Toast disconnected", this.toastId);
  }
}

